@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_0-color.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_1-base.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_2-font.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_3-layout.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_button.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/variables/_fontawsome-variables.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_background-variant.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_background.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_box-emboss.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_box-sizing.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_buttons.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_center-block.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_clearfix.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_color.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_font-smooth.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_font.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_forms.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_gradients.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_hide-text.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_icon.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_image.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_keyframe.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_labels.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_layout.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_links.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_list.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_navigation.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_opacity.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_pagination.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_panels.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_preserve3d.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_progress-bar.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_reset-filter.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_resize.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_responsive-visibility.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_sass-inline-svg-data.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_sass-inline-svg.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_size.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_tab-focus.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_table-row.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_text.scss";
@import "/Users/phamh/Sites/d9/latino/htdocs/sites/default/themes/si_nmal/sass/helpers/_vendor-prefixes.scss";
$icon-size: 40px;
.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal-header .close {
  position: absolute;
  background: transparent;
  border-radius: 50%;
  right: -1.5rem;
  top: -3rem;
  @include rem(width, $icon-size);
  @include rem(height, $icon-size);
  color: $btn-bg;
  @include rem(font-size, $font-size-base*2);
  line-height: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-color: transparent;
  appearance: none;

  &:hover,
  &:focus {
    outline: 2px solid $btn-bg;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;

  // Added specificity to override padding.
  .layout.layout--teaser {
    a.inner {
      border-bottom: none;
    }
    .b-text-wrapper {
      padding: 0;
    }
    figure {
      margin: 0;
    }
  }
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 980px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
